<template>
  <div class="profile">
    <p class='profileHead' style=''>Профиль</p>
    <div class='userBlock'>
      <p style='font-size:36px;'>{{ user.emoji }}</p>
      <div class='userBlockText'>
        <div class='userLogin'>
          <p style='font-weight: 700;font-size: 18px;margin-right:5px;'>{{ user.login }}</p>
          <img v-if='user.verification' src='../assets/verification.svg'/>
        </div>
        <p style='font-size: 14px;'>id: <span style='color:#727272;'>{{ user._id.slice(3,12) }}</span></p>
      </div>
    </div>
    <div class='profileBlock'>
      <p>Статус верификации</p>
      <p v-if='user.verification' style='font-weight: 700; color:#007BFF;'>пройдена</p>
      <p v-else style='font-weight: 700; color: #FF3B30;'>не пройдена</p>
    </div>
    <div class='profileBlock'>
      <p>Всего сделок</p>
      <p>{{ win + lose }}</p>
    </div>
    <div class='profileBlock'>
      <p>Прибыльные сделки</p>
      <p>{{ win }}</p>
    </div>
    <div class='profileBlock'>
      <p>Убыточные сделки</p>
      <p>{{ lose }}</p>
    </div>
    <div class='logoutButton' @click='hideLogout'>
      <p>Выйти из профиля</p>
      <img src='../assets/logoutDoor.svg'/>
    </div>
    <div class="logoutFull" @click="hideLogout" v-if="logout">
      <div class="logoutArea">
        <p>Вы уверены что хотите выйти из аккаунта?</p>
        <div class="logoutButtons">
          <div class="acceptLogout" @click="acceptLogout">Да</div>
          <div class="cancelLogout">Нет</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'

export default {
  name: 'ProfileComponent',
  data() {
    return {
      active: "profile",
      user: {_id: "356kgkgkgkgkgkgk"},
      win: " ",
      lose: " ",
      logout: false
    }
  },
  setup() {
    const toast = useToast()
    return {toast}
  },
  methods: {
    getStats() {
      axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/bet/stats`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.token
        }
      })
      .then(res => {
        this.win = res.data.result.win
        this.lose = res.data.result.lose
      })
    },
    hideLogout() {
      if (this.logout) this.logout = false
      else this.logout = true
    },
    acceptLogout(){
      this.toast.success("Вы успешно вышли", {timeout: 2000})
      window.location.href = '/'
      this.$store.commit('logout')
    },
  },  
  mounted() {
    this.user = this.$store.getters.getUserData
    this.getStats()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.profile {
  height:100vh;
  width: 20vw;
  background:black;
  /* position: absolute; */
  border-left: 1px solid rgba(77, 77, 77, 0.452);
  border-right: 1px solid rgba(77, 77, 77, 0.452);
  /* left:6%; */
  text-align: left;
  padding: 20px;
}
.userBlock {
  border-radius:10px;
  background-color: #141414;
  border: 1px solid #FFFFFF0D;
  padding: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 5%;
}
.profileBlock {
  border-radius:10px;
  background-color: #141414;
  border: 1px solid #FFFFFF0D;
  padding: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
.profileHead {
  font-weight: 700;font-size: 18px;margin-top: 10%;margin-bottom: 5%;
}
.profileBlock p:nth-child(1) {
  font-size: 18px;
}
.profileBlock p:nth-child(2) {
  font-weight: 700;
  color:#5F5F5F; 
  font-size: 16px;
}
.userLogin {
  display: flex;
  align-items: center;
}
.userBlockText {
  margin-left:10px;
}
.logoutButton {
  display: none;
}
.logoutFull {
  width:100vw;
  height:100vh;
  position: absolute;
  left:0;
  top:0;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoutArea {
  border-radius: 10px;
  background-color: #0F0F0F;
  padding: 3%;
  width:80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 700;
  text-align: center;
  padding: 40px;
}
.logoutArea p {
  font-size: 18px;
}
.logoutButtons {
  display: grid;
  margin-top:20px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap:20px;
  color: white;
}
.acceptLogout {
  background-color: #000000;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  cursor: pointer;
  text-align: center;
}
.cancelLogout {
  background-color: #007BFF;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  cursor: pointer;
  text-align: center;
}
@media (max-width:968px) {
  .profile {
    width:100%;
    background-image: url('../assets/backgroundMobile.png');
    background-position: 0 50vh;
    background-size: 200%;
    background-repeat: no-repeat;
    position: absolute;
    top:0;
    z-index: 8;
  }
  .profileHead {
    font-size:24px;
  }
  .profileBlock {
    margin-bottom: 3%;
    padding:5%;
  }
  .userBlock {
    margin-bottom: 3%;
  }
  .logoutButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    background-color: #FF3B3026;
    border-radius: 8px;
    padding:16px;
    gap:5px;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #FFFFFF0D;
  }
}
@media (max-height:700px) {
  .profileBlock p:nth-child(1) {
    font-size: 16px;
  }
  .profileBlock p:nth-child(2) {
    font-weight: 700;
    color:#5F5F5F; 
    font-size: 14px;
  }
}
</style>
