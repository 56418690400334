<template>
  <div class="withdrawBlock" @click="$event.target.className=='withdrawBlock' ? this.$emit('onCloseWithdraw') : null">
    <div class='withdrawArea'>
      <div class="withdrawAreaHeader">
        <p style="font-size: 24px; font-weight: 700;">Вывести средства</p>
        <img src="../assets/logo/closeDeposit.svg" style="cursor: pointer;" @click="this.$emit('onCloseWithdraw')"/>
      </div>
      <div class="withdrawZone">
        <div class="withdrawMethods">
          <div class="methodElement" id="card" @click="selectMethod">
            <div class="methodHead selectedMethod">
              <p>Bank (Russia)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="card_ua" @click="selectMethod">
            <div class="methodHead">
              <p>Bank (Ukraine)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="card_kz" @click="selectMethod">
            <div class="methodHead">
              <p>Bank (Kazakhstan)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="eth" @click="selectMethod">
            <div class="methodHead">
              <p>Ethereum</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/ethDeposit.svg"/>
              <p>Ethereum</p>
            </div>
          </div>
          <div class="methodElement" id="usdt" @click="selectMethod">
            <div class="methodHead">
              <p>USDT</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/usdtDeposit.svg"/>
              <p>Tether / TRC20</p>
            </div>
          </div>
          <div class="methodElement" id="btc" @click="selectMethod">
            <div class="methodHead">
              <p>Bitcoin</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/btcDeposit.svg"/>
              <p>Bitcoin</p>
            </div>
          </div>
        </div>
        <div class="enterSum">
          <input type="number" v-model="withdrawSum" step="any" placeholder="Сумма в рублях" required/>
          <input type="text" v-model="invoiceNumber" placeholder="Номер счета" required/>
          <button class="withdrawButton" @click='withdrawClick'>Вывести</button>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'

export default {
  name: 'withdrawComponent',
  data() {
    return {
      user: {},
      withdrawMethod: "card",
      withdrawSum: null,
      invoiceNumber: null
    }
  },
  setup() {
    const toast = useToast()
    return {toast}
  },
  methods: {
    selectMethod(event) {
      let heads = document.querySelectorAll('.methodHead')
      for (let head of heads) head.className = "methodHead"
      let element = event.target.closest('div .methodElement')
      element.children[0].classList.add('selectedMethod')
      this.withdrawMethod = element.id
    },
    withdrawClick() {
      if (this.withdrawSum && this.invoiceNumber) {
        let but = document.querySelectorAll('.withdrawButton')
        for (let b of but) {
          b.disabled = true
          b.classList.add('withdrawButtonInactive')
        }
        axios.post(`https://${process.env.VUE_APP_SERVERHOST}/api/payout`, {
            amount: this.withdrawSum,
            type: this.withdrawMethod,
            recipient: this.invoiceNumber,
            
          }, {headers: {
              Authorization: "Bearer " + this.$store.state.user.token
            }})
          .then(res => {
            console.log(res)
            if (res.data.result.status) {
              this.toast.success(res.data.result.message)
              for (let b of but) {
                b.disabled = false
                b.className = "withdrawButton"
              }
              axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/users/me`, {
                headers: {
                  Authorization: 'Bearer ' + this.$store.state.user.token
                }
              })
              .then(res => {
                this.$store.commit('setUserData', res.data.result)
              })
            }
            else {
              this.toast.error(res.data.result.message)
              for (let b of but) {
                b.disabled = false
                b.className = "withdrawButton"
              }
            }
            
          })
          .catch(err => {
            this.toast.error(err.response.data.message)
            for (let b of but) {
              b.disabled = false
              b.className = "withdrawButton"
            }
          })
      }
      else {
        let but = document.querySelectorAll('.withdrawButton')
        this.toast.error("Вы не ввели сумму или номер счета")
        for (let b of but) {
          b.disabled = true
          b.classList.add('withdrawButtonInactive')
          
          setTimeout(() => {
            for (let b of but) {
              b.disabled = false
              b.className = "withdrawButton"
            }
          },700)
        }
      }
      
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData
  }
}
</script>

<style scoped>
.withdrawBlock {
  position: absolute;
  left: 0;
  top:0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.withdrawArea {
  width:fit-content;
  border-radius: 10px;
  background-color: #0E0E0E;
  text-align: left;
  padding:1.5%;
  border: 1px solid #FFFFFF0D;
}
.withdrawAreaHeader {
  display: grid;
  width:100%;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}
.withdrawAreaHeader img {
  justify-self:right;
}
.withdrawZone {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  gap:20px;
}
.withdrawMethods {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
}
.methodElement {
  background-color: #0F0F0F;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  cursor: pointer;
}
.descMethod {
  padding: 7% 0 7% 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descMethod p {
  font-size: 12px;
  line-height: 14px;
  margin-left:5px;
}
.enterSum {
  display: flex;
  flex-direction: column;
}
.enterSum input {
  background-color: #0F0F0F;
  outline: none;
  border: 1px solid #FFFFFF4D;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 10px;
}
.withdrawButton {
  background-color: #007BFF;
  border: none;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:14px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.withdrawButtonInactive {
  cursor: auto;
  background-color: #4e4e4e;
}
.methodHead {
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid white;
}
.methodHead p{
  font-size: 10px;
  text-align: center;
  padding:2px;
}
.selectedMethod {
  background-color: #007BFF;
  border-bottom: 1px solid #FFFFFF0D;
}
@media (max-width: 968px) {
  .withdrawZone {
    grid-template-columns: 1fr;
  }
  .withdrawArea {
    padding:5%;
  }
  .withdrawAreaHeader {
    grid-template-columns: 2fr 1fr;
  }
}
</style>
