<template>
  <div class="depositCardBlock">
    <div class="depositCardArea">
      <div class="depositCardHead">
        <p style="font-size: 20px; font-weight: 700;">Пополнить баланс</p>
        <img src="../assets/logo/closeDeposit.svg" style="cursor: pointer;" @click="this.$emit('onCloseDepositCard')"/>
      </div>
      <div class="methodImg" v-if="depositMethod.slice(0,4) == 'card'">
        <img src="../assets/logo/bank.svg"/>
        <p>Интернет-банк<br>Оплата</p>
      </div>
      <div class="methodImg" v-if="depositMethod == 'eth'">
        <img src="../assets/logo/ethDeposit.svg"/>
        <p>Ethereum</p>
      </div>
      <div class="methodImg" v-if="depositMethod == 'usdt'">
        <img src="../assets/logo/usdtDeposit.svg"/>
        <p>USDT / TRC20</p>
      </div>
      <div class="methodImg" v-if="depositMethod == 'btc'">
        <img src="../assets/logo/btcDeposit.svg"/>
        <p>{{ depositMethod.toUpperCase() }}</p>
      </div>
      <div class="depositInputs">
        <p>К оплате</p>
        <input v-if='depositData.type.slice(0,4) == "card"' type="text" class="depositInput" :value="depositData.amount" readonly @click='copyInput'/>
        <input v-else type="text" class="depositInput" :value="depositData.details.summ_pay" readonly @click='copyInput'/>

        <p v-if ='depositData.type.slice(0,4) == "card"'>Банк перевода</p>
        <p v-else>Валюта перевода</p>
        <input v-if ='depositData.type.slice(0,4) == "card"' type="text" class="depositInput" :value="depositData.details.bank_name" readonly @click='copyInput'/>
        <input v-else type="text" class="depositInput" :value="depositData.type.toUpperCase()" readonly @click='copyInput'/>

        <p v-if ='depositData.type.slice(0,4) == "card"'>Номер карты</p>
        <p v-else>Адрес кошелька</p>
        <input v-if ='depositData.type.slice(0,4) == "card"' type="text" class="depositInput" :value="depositData.details.card" readonly @click='copyInput'/>
        <input v-else type="text" class="depositInput" :value="depositData.details.number" readonly @click='copyInput'/>

        <p v-if ='depositData.type.slice(0,4) == "card"'>Номер телефона</p>
        <input v-if ='depositData.type.slice(0,4) == "card"' type="text" class="depositInput" :value="depositData.details.number" readonly @click='copyInput'/>
        <p v-if ='depositData.type.slice(0,4) == "card"'>ФИО получателя</p>
        <input v-if ='depositData.type.slice(0,4) == "card"' type="text" class="depositInput" :value="depositData.details.fio_card" readonly @click='copyInput'/>
        <button class="checkDeposit" @click='checkDeposit'>
          Проверить платёж
        </button>
        <div style='margin: 0 auto; text-align: center; margin-top:5px;'>
          <p id='checkDepositText'>{{ checkDepositText }}</p>
        </div>
        
      </div>
      
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'

export default {
  name: 'DepositComponent',
  data() {
    return {
      user: {},
      depositMethod: 'bankRussia',
      depositSum: null,
      depositData: {amount: 0, type: "card", details: {summ_pay: 0, }},
      checkDepositText: null
    }
  },
  props: ['depositDataParent', 'depositMethodParent'],
  setup() {
    const toast = useToast()
    return {toast}
  },
  methods: {
    checkDeposit() {
      let b = document.querySelector('.checkDeposit')
      console.log(b)
      b.disabled = true
      b.classList.add('checkDepositInactive')
      let paymentID = this.depositData._id
      axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/payment/${paymentID}`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.token
        }
      })
      .then(res => {
        console.log(res)
        let elem = document.getElementById('checkDepositText')
        b.disabled = false
        b.className = "checkDeposit"
        if (res.data.result.status) {
          elem.className = "successfullyDeposit"
          this.checkDepositText = "Средства зачислены на счёт!"
          axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/users/me`, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.user.token
            }
          })
          .then(res => {
            this.$store.commit('setUserData', res.data.result)
          })
        }
        else {
          b.disabled = false
          b.className = "checkDeposit"
          elem.className = "waitingDeposit"
          this.checkDepositText = "Средства еще не зачислены!"
          this.toast.error("Средства ещё не зачислены!")
        }
      })
    },
    copyInput(event) {
      event.target.select();
      document.execCommand('copy');
      this.toast.success("Вы успешно скопировали этот текст!")
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData
    this.depositData = this.depositDataParent
    this.depositMethod = this.depositMethodParent
  }
}
</script>

<style scoped>
.depositCardBlock {
  position: absolute;
  top:0;
  left: 0;
  backdrop-filter: blur(5px);
  width:100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9001;
}
.depositCardArea {
  width:fit-content;
  border-radius: 10px;
  background-color: #0E0E0E;
  text-align: left;
  padding:1.5%;
  border: 1px solid #FFFFFF0D;
}
.depositCardHead {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.depositCardHead img{
  justify-self: right;
}
.methodImg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:17px;
  font-size: 19px;
}
.methodImg img{
  width:40px;
  margin-right: 5px;
}
.depositInputs {
  width:100%;
  padding:12px;
  padding-bottom: 20px;
  font-size: 14px;
}
.depositInputs p {
  margin-bottom: 5px;
}
.depositInput {
  width:100%;
  background-color: #0E0E0E;
  border: 1px solid #FFFFFF4D;
  outline: none;
  border-radius: 8px;
  padding:7px 15px 7px 15px;
  padding-right:10%;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  background-image: url('../assets/copy.svg');
  background-size: 5%;
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
.checkDeposit {
  width:100%;
  background-color: #007BFF;
  border: none;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}
.checkDepositInactive {
  cursor: auto;
  background-color: #4e4e4e;
}
.successfullyDeposit {
  color: #00C896;
}
.waitingDeposit {
  color: #FF0000;
}
@media(max-width:968px) {
  .depositCardHead {
    padding:15px;
  }
}
</style>
