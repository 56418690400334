import { io } from "socket.io-client"


const URL = `https://socket.${process.env.VUE_APP_SERVERHOST}/`;

export const useSocketIO = (user_token) => {
	const socket = io(URL, {
		auth: {
			token: user_token
		},
		transports: ["websocket"],
	})
	return {
			socket
	}
}