<template>
  <div class="sidebar">
    <a href='/'><img src='../assets/logo-light.svg' style='width:42px; margin-top:50%;'/></a>
    <div class='buttonsSidebar'>
      <div class='sideButton activeSideButton' id='home' @click='clickSideButton'>
        <img class='activeSideButtonSvg' id='home' src='../assets/sideButtons/home.svg'/>
      </div>
      <div class='sideButton' id='profile' @click='clickSideButton'>
        <img id='profile' src='../assets/sideButtons/profile.svg'/>
      </div>
      <div class='sideButton' id='wallet' @click='clickSideButton'>
        <img  id='wallet' src='../assets/sideButtons/wallet.svg'/>
      </div>
      <div class='sideButton' id='actives' @click='clickSideButton'>
        <img id='actives' src='../assets/sideButtons/actives.svg'/>
      </div>
      <div class='sideButton' id='faq' @click='clickSideButton'>
        <img id='faq' src='../assets/sideButtons/faq.svg'/>
      </div>
      <div class='sideButton' id='notifications' @click='clickSideButton'>
        <img id='notifications' v-if='this.$store.getters.getReadable' src='../assets/sideButtons/unreadNotifications.svg' style='height:30px; width: 30px;'/>
        <img id='notifications' v-else src='../assets/sideButtons/notifications.svg'/>
      </div>
    </div>
    <div class='logoutButton' @click='logoutClick'>
      <img id='logout' src='../assets/sideButtons/logout.svg'/>
    </div>
    <div class="logoutFull" @click="hideLogout" v-if="logout">
      <div class="logoutArea">
        <p>Вы уверены что хотите выйти из аккаунта?</p>
        <div class="logoutButtons">
          <div class="acceptLogout" @click="acceptLogout">Да</div>
          <div class="cancelLogout">Нет</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'

export default {
  name: 'SideBar',
  data() {
    return {
      sideActive: "home",
      logout: false
    }
  },
  setup() {
    const toast = useToast()
    return {toast}

  },
  methods: {
    clickSideButton(event) {
      
      let elems = document.querySelectorAll('.sideButton')
      elems.forEach(elem => {
        elem.className = "sideButton"
        elem.children[0].className = ""
      });
      this.sideActive = event.target.id
      let div = document.querySelector("#"+event.target.id)
      div.classList.add('activeSideButton')
      let img = div.querySelector('img')
      img.classList.add('activeSideButtonSvg')
      this.$emit('newActive', {
          sideActive: this.sideActive
        })
    },
    logoutClick() {
      this.logout = true
    },
    acceptLogout(){

      this.toast.success("Вы успешно вышли", {timeout: 2000})
      window.location.href = '/'
      this.$store.commit('logout')
    },
    hideLogout(event) {
      let t = event.target
      if (t.className == 'logoutFull' || t.className == 'cancelLogout') this.logout = false
    },
    hideSideBarChild() {
      alert("baby")
    },
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sidebar {
  min-width: 5vw;
  width: 5vw;
  max-width: 5vw;
  height: 100vh;
  background: #000000;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.buttonsSidebar {
  margin-top:40%;
}
.sideButton {
  margin:20px;
  cursor: pointer;
  padding:15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeSideButtonSvg {
  filter: invert(59%) sepia(79%) saturate(7500%) hue-rotate(200deg) brightness(104%) contrast(108%);
}
.activeSideButton {
  background: #0E0E0E;
  border-radius:13px;
}
.sideButton:hover {
  background: #0E0E0E;
  border-radius:13px;
}
.logoutButton {
  position: fixed;
  margin:20px;
  cursor: pointer;
  padding:15px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom:5%;
  border-radius: 13px;
}
.logoutButton:hover {
  background-color: #0F0F0F;
}
.logoutFull {
  width:100vw;
  height:100vh;
  position: absolute;
  left:0;
  top:0;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoutArea {
  border-radius: 10px;
  background-color: #0F0F0F;
  padding: 3%;
  width:35%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 700;
}
.logoutArea p {
  
  font-size: 24px;
}
.logoutButtons {
  display: grid;
  margin-top:20px;
  width: 80%;
  grid-template-columns: 1fr 1fr;
  gap:20px;
  color: white;
}
.acceptLogout {
  background-color: #000000;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  cursor: pointer;
}
.cancelLogout {
  background-color: #007BFF;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  cursor: pointer;
}
@media (max-width: 968px) {
  .sidebar {
    height: fit-content;
    width: 100%;
    max-width: 90vw;
    border-radius: 10px;
    padding:5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom:20px;
    z-index: 8000;
  }
  .sidebar a {
    display: none;
  }
  .buttonsSidebar {
    display: flex;
    flex-direction: row;
    background-color: #000000;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
  }
  .sideButton {
    margin: 5px;
    padding: 10px;
  }
  .logoutButton {
    display: none;
  }
  .buttonsSidebar {
    margin-top: 0;
  }
}
</style>
