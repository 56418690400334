<template>
  <div class="depositBlock" @click="$event.target.className=='depositBlock' ? this.$emit('onCloseDeposit') : null">
    <div class='depositArea'>
      <div class="depositAreaHeader">
        <p style="font-size: 24px; font-weight: 700;">Пополнить баланс</p>
        <img src="../assets/logo/closeDeposit.svg" style="cursor: pointer;" @click="this.$emit('onCloseDeposit')"/>
      </div>
      <div class="depositZone">
        <div class="depositMethods">
          <div class="methodElement" id="card" @click="selectMethod">
            <div class="methodHead selectedMethod">
              <p>Bank (Russia)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="card_ua" @click="selectMethod">
            <div class="methodHead">
              <p>Bank (Ukraine)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="card_kz" @click="selectMethod">
            <div class="methodHead">
              <p>Bank (Kazakhstan)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="eth" @click="selectMethod">
            <div class="methodHead">
              <p>Ethereum</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/ethDeposit.svg"/>
              <p>Ethereum</p>
            </div>
          </div>
          <div class="methodElement" id="usdt" @click="selectMethod">
            <div class="methodHead">
              <p>USDT</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/usdtDeposit.svg"/>
              <p>Tether / TRC20</p>
            </div>
          </div>
          <div class="methodElement" id="btc" @click="selectMethod">
            <div class="methodHead">
              <p>Bitcoin</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/btcDeposit.svg"/>
              <p>Bitcoin</p>
            </div>
          </div>
          <div class="methodElement" id="promo" @click="selectMethod">
            <div class="methodHead">
              <p>Promo</p>
            </div>
            <div class="descMethod">
              <img src="../assets/promo.svg" width='30px'/>
            </div>
          </div>
        </div>
        <div class="enterSum">
          <input type="text" v-model="promo" placeholder="Введите промокод" v-if='isPromo'/>
          <input type="number" v-model="depositSum" step="any" placeholder="Сумма в рублях" v-else/>
          <button class="depositButton" @click="activatePromo" v-if='isPromo'>Активировать</button>
          <button class="depositButton" @click="showCardButton" v-else>Пополнить</button>
        </div>
      </div>
      
    </div>
  </div>
  <DepositCard @onCloseDepositCard="closeCard" :depositDataParent="depositData" :depositMethodParent="depositMethod" v-if="showCard"/>
</template>

<script>
import axios from 'axios'
import { nextTick } from 'vue'
import { useToast } from 'vue-toastification'
import DepositCard from './DepositCard.vue'

export default {
  name: 'DepositComponent',
  data() {
    return {
      user: {},
      depositMethod: "card",
      depositSum: null, 
      depositData: {},
      showCard: false,
      isPromo: false,
      promo: ""
    }
  },
  props: ['onCloseDeposit'],
  components: {
    DepositCard
  },
  setup() {
    const toast = useToast()
    return {toast}
  },
  methods: {
    selectMethod(event) {
      let heads = document.querySelectorAll('.methodHead')
      for (let head of heads) head.className = "methodHead"
      let element = event.target.closest('div .methodElement')
      element.children[0].classList.add('selectedMethod')
      this.depositMethod = element.id
      if (element.id == "promo") this.isPromo = true
      else this.isPromo = false
    },
    getPromocodes() {
      axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/promocode`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token
          }
        })
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
    },
    activatePromo() {
      axios.post(`https://${process.env.VUE_APP_SERVERHOST}/api/promocode/${this.promo}`,{}, {
        headers: {Authorization: "Bearer " + this.$store.state.user.token}})
        .then(res => {
          console.log("АКТИВИРОВАН", res)
          this.toast.success("Промокод был успешно активирован!")
        })
        .catch(err => {
          this.toast.error(err.response.data.message)
          console.log(this.$store.state.user)
        })
    },
    showCardButton() {
      if (this.depositSum) {
        
        let but = document.querySelectorAll('.depositButton')
        for (let b of but) {
          b.disabled = true
          b.classList.add('depositButtonInactive')
        }
        axios.post(`https://${process.env.VUE_APP_SERVERHOST}/api/payment`, {
          amount: this.depositSum,
          type: this.depositMethod
        }, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token
          }
        })
        .then(res => {
          this.depositData = res.data.result
          this.showCard = true
          let el = document.querySelector('.depositBlock')
          el.style.display = 'none'
        })
        .catch(err => {
          this.toast.error(err.response.data.message)
          for (let b of but) {
            b.disabled = false
            b.className = "depositButton"
          }
        })
      }
      else {
        let but = document.querySelectorAll('.depositButton')
        this.toast.error('Введите сумму пополнения', {timeout:2000})
        for (let b of but) {
          b.disabled = true
          b.classList.add('depositButtonInactive')
          
          setTimeout(() => {
            for (let b of but) {
              b.disabled = false
              b.className = "depositButton"
            }
          },700)
        }
      }
      
    },
    async closeCard() {
      this.showCard = false
      let el = document.querySelector('.depositBlock')
      el.style.display = 'flex'
      el = document.querySelectorAll('.depositButton')
      for (let b of el) {
            b.disabled = false
            b.className = "depositButton"
          }
      el = document.querySelector('.enterSum input')
      await nextTick()
      el.value = null
      this.depositSum = null
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData
    this.getPromocodes()
  }
}
</script>

<style scoped>
.depositBlock {
  position: absolute;
  left: 0;
  top:0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
}
.depositArea {
  width:fit-content;
  border-radius: 10px;
  background-color: #0E0E0E;
  text-align: left;
  padding:1.5%;
  border: 1px solid #FFFFFF0D;
}
.depositAreaHeader {
  display: grid;
  width:100%;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}
.depositAreaHeader img {
  justify-self:right;
}
.depositZone {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  gap:20px;
}
.depositMethods {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
}
.methodElement {
  background-color: #0F0F0F;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  cursor: pointer;
}
.descMethod {
  padding: 7% 0 7% 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descMethod p {
  font-size: 12px;
  line-height: 14px;
  margin-left:5px;
}
.enterSum {
  display: flex;
  flex-direction: column;
}
.enterSum input {
  background-color: #0F0F0F;
  outline: none;
  border: 1px solid #FFFFFF4D;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 10px;
}
.depositButton {
  background-color: #007BFF;
  border: none;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:14px;
  font-weight: 700;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
}
.depositButtonInactive {
  cursor: auto;
  background-color: #4e4e4e;
}
.methodHead {
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid white;
}
.methodHead p{
  font-size: 10px;
  text-align: center;
  padding:2px;
}
.selectedMethod {
  background-color: #007BFF;
  border-bottom: 1px solid #FFFFFF0D;
}
@media (max-width: 968px) {
  .depositZone {
    grid-template-columns: 1fr;
  }
  .depositArea {
    padding:5%;
  }
  .depositAreaHeader {
    grid-template-columns: 2fr 1fr;
  }
}
</style>
